

























































import Vue from 'vue'
import { ApiResponse, Inventory, Requisition } from '@/interfaces'
import { mapState } from 'vuex'

interface ContactProject {
  id: string
  projectNumber: string
  inventory?: Inventory | null
  requisition?: Requisition | null
  createdAt: string
  structureName: string
  structureType: string
  structureColor: string
  status: string
}

interface Badge {
  color: string
  value: string
}

interface NatureObject {
  label: string
  value: string
}

interface DropdownAction {
  action: string
  inventory?: Inventory
  project?: ContactProject
}

export default Vue.extend({
  props: {
    contact: {
      type: String,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
    contactNumber: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      projects: [] as ContactProject[],
      emptyProject: null as number | null,
      busy: false,
      natures: [] as NatureObject[],
      customColors: [
        { color: '#48C989', percentage: 25 },
        { color: '#F7AD57', percentage: 50 },
        { color: '#E8584F', percentage: 75 },
        { color: '#263238', percentage: 100 },
      ],
      nextStockId: null as string | null,
    }
  },
  computed: {
    ...mapState(['user']),
    statusFilters() {
      return [
        { text: 'Ouvert', value: 'ouvert' },
        { text: 'Prospect', value: 'prospect' },
        { text: 'Rendez-vous pris', value: 'rendez-vous_pris' },
        { text: 'En attente', value: 'en_attente' },
        { text: 'En cours', value: 'en_cours' },
        { text: 'Inventaire', value: 'inventaire' },
        { text: 'Préparation de vente', value: 'preparation_vente' },
        { text: 'Vente en cours', value: 'vente_en_cours' },
        { text: 'Difficulté', value: 'difficulte' },
        { text: 'Deuxième relance', value: '2eme_relance' },
        { text: 'Mise en demeure', value: 'mise_en_demeure' },
        { text: 'Reglé', value: 'regle' },
        { text: 'Clôturé', value: 'clos' },
        { text: 'Annulé', value: 'annule' },
      ]
    },
  },
  watch: {
    contact(newVal) {
      if (newVal && this.activeTab === 'projects') {
        this.getProjects()
      }
    },
    activeTab(newVal) {
      if (newVal === 'projects' && this.contact) {
        this.getProjects()
        this.getNextStockId()
      }
    },
  },
  mounted() {
    if (this.contact && this.activeTab === 'projects') {
      this.getProjects()
      this.getNextStockId()
    }
  },
  methods: {
    formatDate(str: string, hours = false): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        if (hours) {
          const day = new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          }).format(date)
          const hour = new Intl.DateTimeFormat('fr-FR', {
            hour: 'numeric',
            minute: 'numeric',
          }).format(date)

          return `${day} à ${hour}`
        } else {
          return new Intl.DateTimeFormat('fr-FR').format(date)
        }
      } else {
        return '-'
      }
    },
    getStatusBadge(project: ContactProject): Badge {
      switch (project.status) {
        case 'ouvert':
          return {
            color: '#EFE6FD',
            value: 'Ouvert',
          }

        case 'prospect':
          return {
            color: '#EFE6FD',
            value: 'Prospect',
          }
        case 'rendez-vous_pris':
          return {
            color: '#EFE6FD',
            value: 'Rendez-vous pris',
          }
        case 'en_attente':
          return {
            color: '#EFE6FD',
            value: 'En attente',
          }
        case 'en_cours':
          return {
            color: '#EFE6FD',
            value: 'En cours',
          }
        case 'inventaire':
          return {
            color: '#EFE6FD',
            value: 'Inventaire',
          }
        case 'preparation_vente':
          return {
            color: '#EFE6FD',
            value: 'Préparation de vente',
          }
        case 'vente_en_cours':
          return {
            color: '#EFE6FD',
            value: 'Vente en cours',
          }
        case 'difficulte':
          return {
            color: '#EFE6FD',
            value: 'Difficulté',
          }
        case '2eme_relance':
          return {
            color: '#EFE6FD',
            value: 'Deuxième relance',
          }
        case 'mise_en_demeure':
          return {
            color: '#EFE6FD',
            value: 'Mise en demeure',
          }
        case 'regle':
          return {
            color: '#EFE6FD',
            value: 'Reglé',
          }
        case 'clos':
          return {
            color: '#EFE6FD',
            value: 'Clôturé',
          }
        case 'annule':
          return {
            color: '#EFE6FD',
            value: 'Annulé',
          }

        default:
          return {
            color: '#EFE6FD',
            value: '-',
          }
      }
    },
    getProjects() {
      if (!this.busy && this.projects.length === 0) {
        this.busy = true
        const contactId = Number(this.contact)

        this.$api
          .get(`/projects/contact/${contactId}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            apiResponse.data.forEach((project: ContactProject) => {
              if (project.inventory || project.requisition) {
                this.projects.push(project)
              }
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    editProject(project: ContactProject) {
      if (project.inventory) {
        this.$router.push({
          name: 'inventory_edit',
          params: {
            projectId: project.id ?? '',
            inventoryId: project.inventory?.toString() ?? '',
          },
        })
      } else if (project.requisition) {
        this.$router.push({
          name: 'requisition_edit',
          params: {
            projectId: project.id ?? '',
            requisitionId: project.requisition?.toString() ?? '',
          },
        })
      }
    },
    deleteProject(project: ContactProject) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer cet inventaire ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/project/${project.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.getProjects()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    goToProject(project: ContactProject, column: Record<string, string>, cell: HTMLTableCellElement) {
      if (!cell.classList.contains('actions')) {
        this.editProject(project)
      }
    },
    getProjectType(project: ContactProject) {
      return project.inventory ? 'Inventaire' : 'Réquisition'
    },
    getStructureType(project: ContactProject) {
      return project.structureType ?? null
    },
    filterHandler(value: string, row: Record<string, string>, column: Record<string, string>) {
      const property = column['property']

      return row[property] === value
    },
    handleInventoryCommand(command: DropdownAction) {
      // Edit inventory
      if (command.action == 'edit' && command.project) {
        this.editProject(command.project)
      }

      // Delete inventory
      if (command.action == 'delete' && command.project) {
        this.deleteProject(command.project)
      }
    },
    getNextStockId() {
      if (this.nextStockId === null) {
        this.busy = true
        const contactId = Number(this.contact)

        this.$api
          .get(`/contact/${contactId}/next-stock-id`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.nextStockId = `${this.contactNumber}-${apiResponse.data}`
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    getStructureColor(project: ContactProject) {
      return project.structureColor ?? null
    },
  },
})
